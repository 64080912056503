<template>
    <div class="account-vip-page">
        <el-card class="basic-card" shadow="never">
            <div slot="header" class="flex flex-align-center" style="border-radius: 0 10px 0 0;">
                <div class="flex-1">
                    <div class="flex flex-align-center">
                        <div><vip-icon></vip-icon></div>
                        <div class="margin-l-10">
                            <span class="fonts-16 text-weight-600">{{userInfo.accountTypeDesc}}</span>
                            <span class="fonts-14 margin-l-10" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType) < 0 && userInfo.expireTime">{{moment(userInfo.expireTime).format('YYYY-MM-DD')}}到期</span>
                            <span class="fonts-12 color-error margin-l-10" v-if="userInfo.accountType === 'pro-limited'">已到期</span>
                        </div>
                    </div>
                    <div class="fonts-12 color-808080 margin-t-10" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1">账户升级享受更多权益</div>
                </div>
                <!-- <div v-if="userInfo.payBehavioralType !== 'OTHERS'">
                    <el-button type="primary" round size="small" @click="payProBtnEvent">
                        {{ 
                            userInfo.payBehavioralType === 'FREETRAIL' ? '免费试用' : 
                            userInfo.payBehavioralType === 'BUYNOW' ? '立即购买' :
                            userInfo.payBehavioralType === 'IMMEDIATERENEWAL' ? '立即续费': ''
                        }}
                    </el-button>
                </div> -->
            </div>
            <div class="flex flex-justify-between ">
                <div>
                    <div class="fonts-12">
                        <span class="fonts-17 text-weight-600">总流量字符</span>
                        <span class="margin-l-10">{{userInfo.accountTypeDesc}}流量字符+购买的字符包</span>
                        <router-link to="/account/chars" class="margin-l-10">查看详情</router-link>
                    </div>
                    <div class="margin-t-18" v-if="userInfo.accountType === 'internal'"><span class="fonts-15">不限字符</span></div>
                    <div class="margin-t-18 fonts-15" v-else><span>剩{{userInfo.flowQuota.surplus}}字符</span><span class="color-808080"> / 总{{userInfo.flowQuota.limit}}字符</span></div>
                </div>
                <div>
                    <el-button plain round size="small" icon="el-icon-plus" @click="showCharDialog('c10')">字符包</el-button>
                </div>
            </div>
            <div class="margin-t-15" v-if="userInfo && userInfo.accountType === 'internal'">
                <el-progress :percentage="100" color="#00D959" :show-text="false"></el-progress>
            </div>
            <div class="margin-t-15" v-if="userInfo && userInfo.accountType !== 'internal'">
                <el-progress :percentage="(userInfo.flowQuota.surplus/userInfo.flowQuota.limit)*100 || 0" color="#00D959" :show-text="false"></el-progress>
            </div>
        </el-card>
        <!-- <div class="margin-t-20 margin-b-20 text-weight-600">版本对比</div> -->
        <div class="flex flex-justify-center margin-y-20">
            <div class="type-list">
                <div :class="['item', activeType==='vip'?'active':'' ]" @click="activeType='vip'">会员</div>
                <div :class="['item', activeType==='char'?'active':'' ]" @click="activeType='char'">字符包</div>
            </div>
        </div>
        <template v-if="userInfo && userInfo.accountType">
            <div class="vip-type-list" v-if="activeType==='vip'">
                <template v-for="(item, index) in purchaseOptions">
                    <div :key="index" class="item basic-card flex flex-column relative" :class="`bg-${item.type.toLocaleLowerCase()}`">
                        <div class="flex-1">
                            <div class="flex flex-align-center flex-justify-center">
                                <div class="vip-icon" :class="item.type.toLocaleLowerCase()">
                                    <svg-icon :name="`ic-${item.type.toLocaleLowerCase()}`"></svg-icon>
                                </div>
                            </div>
                            <div class="fonts-20 text-weight-700 flex flex-align-center flex-justify-center margin-b-30 margin-t-10">
                                <span>{{item.title}}</span>
                            </div>
                            <template v-for="(detail, dIndex) in item.details">
                                <div class="flex flex-align-center fonts-14 margin-b-16" :key="dIndex">
                                    <svg-icon name="ib-check-o" className="color-success"></svg-icon>
                                    <span class="margin-l-10 flex-1">{{detail.main}}<div class="fonts-12 color-919191 margin-t-4" v-if="detail.secondary">{{detail.secondary}}</div></span>
                                </div>
                            </template>
                        </div>
                        <div class="text-center margin-t-14">
                            <el-button plain round size="medium" @click="applyBtnEvent(item)" v-if="item.showApplyBtn">申请试用</el-button>
                            <el-button plain round size="medium" style="border-color: #2979FF;color: #2979FF;" @click="auditingBtnEvent(item)" v-if="item.showAuditingBtn">审核中</el-button>
                            <el-button plain round size="medium" @click="purchaseBtnEvent(item)" v-if="item.showPurchaseBtn">立即购买</el-button>
                            <el-button plain round size="medium" @click="renewBtnEvent(item)" v-if="item.showRenewBtn">立即续费</el-button>
                            <el-button plain round size="medium" @click="applyBtnEvent(item)" v-if="item.showContactBtn">联系我们</el-button>
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <div class="chars-pakage-list" v-if="activeType==='char'">
            <div class="item basic-card flex flex-column">
                <div class="char-wapper">
                    <div class="char-icon icon-m">
                        <svg-icon name="ib-word-package"></svg-icon>
                    </div>
                    <div class="text-center fonts-17 text-weight-600 margin-t-10">10万字符</div>
                    <div class="text-center fonts-15 text-weight-600 margin-t-15">￥25元<!-- <span class="line-through margin-l-10 fonts-14 color-919191">￥19元</span> --></div>
                    <div class="text-center fonts-14 margin-t-8 color-919191">相当于2.5元/万字符</div>
                </div>
                <div class="text-center" style="margin-top: 21px;">
                    <el-button plain round size="medium" @click="showCharDialog('c10')">立即购买</el-button>
                </div>
            </div>
            <div class="item basic-card flex flex-column">
                <div class="char-wapper">
                    <div class="char-icon icon-m">
                        <svg-icon name="ib-word-package"></svg-icon>
                    </div>
                    <div class="text-center fonts-17 text-weight-600 margin-t-10">50万字符</div>
                    <div class="text-center fonts-15 text-weight-600 margin-t-15">￥99元<!-- <span class="line-through margin-l-10 fonts-14 color-919191">￥59元</span> --></div>
                    <div class="text-center fonts-14 margin-t-8 color-919191">相当于1.98元/万字符</div>
                </div>
                <div class="text-center" style="margin-top: 21px;">
                    <el-button plain round size="medium" @click="showCharDialog('c50')">立即购买</el-button>
                </div>
            </div>
            <div class="item basic-card flex flex-column">
                <div class="char-wapper">
                    <div class="char-icon icon-m">
                        <svg-icon name="ib-word-package"></svg-icon>
                    </div>
                    <div class="text-center fonts-17 text-weight-600 margin-t-10">100万字符</div>
                    <div class="text-center fonts-15 text-weight-600 margin-t-15">￥139元<!-- <span class="line-through margin-l-10 fonts-14 color-919191">￥99元</span> --></div>
                    <div class="text-center fonts-14 margin-t-8 color-919191">相当于1.39元/万字符</div>
                </div>
                <div class="text-center" style="margin-top: 21px;">
                    <el-button plain round size="medium" @click="showCharDialog('c100')">立即购买</el-button>
                </div>
            </div>
            <div class="item basic-card flex flex-column">
                <div class="char-wapper">
                    <div class="char-icon icon-l">
                        <svg-icon name="ib-word-package"></svg-icon>
                    </div>
                    <div class="text-center fonts-17 text-weight-600 margin-t-10">1000万字符</div>
                    <div class="text-center fonts-15 text-weight-600 margin-t-15">￥1251元<!-- <span class="line-through margin-l-10 fonts-14 color-919191">￥99元</span> --></div>
                    <div class="text-center fonts-14 margin-t-8 color-919191">相当于1.251元/万字符</div>
                </div>
                <div class="text-center" style="margin-top: 21px;">
                    <el-button plain round size="medium" @click="showCharDialog('c1000')">立即购买</el-button>
                </div>
                <!-- <div class="tag erp">企业版</div> -->
            </div>
            <div class="item basic-card flex flex-column">
                <div class="char-wapper">
                    <div class="char-icon icon-l">
                        <svg-icon name="ib-word-package"></svg-icon>
                    </div>
                    <div class="text-center fonts-17 text-weight-600 margin-t-10">1亿字以上</div>
                    <!-- <div class="text-center fonts-15 text-weight-900 margin-t-15">&nbsp;</div>
                    <div class="text-center fonts-14 margin-t-8 color-919191">&nbsp;</div> -->
                </div>
                <div class="text-center" style="margin-top: 80px;">
                    <el-button plain round size="medium" @click="connectUsEvent">联系我们</el-button>
                </div>
                <!-- <div class="tag erp">企业版</div> -->
            </div>
        </div>
        <div class="fonts-12 line-height-1.25 padding-20" style="color: #8A8A8A;">*文档翻译、文本翻译、AI工具箱均按照原文字符数消耗流量。会员服务：自购买日起一个自然月（年）内，会员权益生效。系统赠送给用户的流量字符每月更新，不结转，用户单独购买的字符包为一年有效期（<a target="blank" href="https://m15er90ta0.feishu.cn/docx/Yb9XdJZxGoh4vaxlNCwcv9UQnjb">查看详细说明</a>）。活动解释权归LanguageX所有。</div>
        <el-dialog title="试用Pro版" :visible.sync="trialDialog.show" destroy-on-close width="440px" top="20px">
            <div class="text-center padding-l-30 padding-r-30" style="line-height:1.5;">你可以试用7天，试用期间或结束后可以随时续订，到期后未续订，会回退到Free版。</div>
            <div slot="footer" style="text-align: center;">
                <el-button round plain size="small" @click="cancelTrialBtnEvent">取消</el-button> 
                <el-button round type="primary" size="small" :loading="trialDialog.loading" @click="submitTrialBtnEvent">免费试用</el-button>
            </div>
        </el-dialog>
        <el-dialog title="申请信息" :visible.sync="contactUsDialog.show" destroy-on-close @closed="handlerContactUsDialogClose" class="none-padding-body" width="430px" top="20px">
            <div v-if="contactUsDialog.submitted">
                <div class="color-success fonts-24 margin-t-15 text-center">
                    <i class="el-icon-success"></i>
                </div>
                <div class="fonts-15 margin-t-15 text-center">已成功提交申请</div>
                <div class="fonts-12 margin-t-10 text-center">通过审核后会为您创建组织，请注意查看</div>
                <div class="fonts-12 margin-t-10 text-center">请扫码添加微信，备注姓名+企业/高校名称+手机号</div>
                <div class="text-center margin-t-10">
                <template v-if="contactUsDialog.form.edition === 'TRIAL'">
                    <img :src="selectOptions.contactQrCode[0].option" class="border-default radius-10 padding-5" alt="wechat qrcode" style="width: 142px;"/>
                </template>
                <template v-else>
                    <img src="../../../static/images/account/tencent-oa-qrcode-1.png" class="border-default radius-10" alt="wechat qrcode" style="width: 142px;"/>
                </template>
                </div>
            </div>
            <template v-else>
                <div class="fonts-12 bg-fdf6e7 padding-y-8 color-warning text-center">仅用于联系您使用，请放心填写！</div>
                <el-form size="medium" class="margin-t-10" style="padding: 0 60px;">
                    <el-form-item label="联系人" required class="margin-b-20">
                        <el-input v-model="contactUsDialog.form.contact" maxlength="50" show-word-limit placeholder="请输入联系人"></el-input>
                    </el-form-item>
                    <el-form-item label="职务" required class="margin-b-20" v-if="contactUsDialog.form.edition === 'ULTIMATE'">
                        <el-input v-model="contactUsDialog.form.title" maxlength="50" show-word-limit placeholder="请输入职务"></el-input>
                    </el-form-item>
                    <el-form-item label="联系手机" required class="margin-b-20">
                        <el-input v-model="contactUsDialog.form.phone" maxlength="50" show-word-limit placeholder="请输入联系手机"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱" required class="margin-b-20">
                        <el-input v-model="contactUsDialog.form.email" maxlength="50" show-word-limit :placeholder="contactUsDialog.form.edition === 'TRIAL' ? '请输入企业邮箱' : '请输入高校邮箱'"></el-input>
                    </el-form-item>
                    <el-form-item :label="contactUsDialog.form.edition === 'TRIAL' ? '机构名称' : '高校名称'" required class="margin-b-20">
                        <el-input v-model="contactUsDialog.form.name" maxlength="50" show-word-limit :placeholder="contactUsDialog.form.edition === 'TRIAL' ? '请输入机构名称' : '请输入高校名称'"></el-input>
                    </el-form-item>
                    <el-form-item label="所授课程" required class="margin-b-20" v-if="contactUsDialog.form.edition === 'ULTIMATE'">
                        <el-input v-model="contactUsDialog.form.course" maxlength="50" show-word-limit placeholder="请输入所授课程"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="机构类型" required class="margin-b-20">
                        <el-select v-model="contactUsDialog.form.orgType" placeholder="请选择机构类型" style="width: 100%;">
                            <template v-for="item in selectOptions.orgType">
                                <el-option :label="item.display" :value="item.option" :key="item.option"></el-option>
                            </template>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="成员人数" required class="margin-b-20">
                        <el-select v-model="contactUsDialog.form.orgScale" placeholder="请选择成员人数" style="width: 100%;">
                            <template v-for="item in selectOptions.orgScale">
                                <el-option :label="item.display" :value="item.option" :key="item.option"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="使用LanguageX的主要场景" required class="margin-b-20">
                        <el-input v-model="contactUsDialog.form.businessScene" type="textarea" rows="5" placeholder="请输入使用LanguageX的主要场景"></el-input>
                    </el-form-item>
                    <el-form-item required class="margin-b-20" v-if="contactUsDialog.form.edition === 'ULTIMATE'">
                        <span class="fonts-14 zindex-10" style="color: #606266; line-height: 36px;"><span style="color: #F56C6C;margin-right: 4px;">*</span>请上传申请表附件<span><a :href="`https://nextcat-dev.oss-cn-beijing.aliyuncs.com/files/public/LanguageX高校试用申请表样例.docx?ttt=${new Date().getTime()}`" class="margin-l-15 cursor-pointer">下载申请表样例</a></span></span>
                        <upload-oss ref="uploadTableNode" title="点击上传附件" accept=".doc,.docx"></upload-oss>
                    </el-form-item>
                </el-form>
            </template>
            <div slot="footer" class="text-center" v-if="contactUsDialog.submitted">
                <el-button type="primary" size="mini" round @click="contactUsDialog.show = false;">确定</el-button>
            </div>
            <div slot="footer" class="text-center" v-else>
                <el-button size="mini" plain round @click="contactUsDialog.show = false">取消</el-button>
                <el-button type="primary" size="mini" round :loading="contactUsDialog.loading" @click="submitContactsForm">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="申请信息" :visible.sync="contactUsDialog.qrcodeShow" destroy-on-close width="430px" top="20px">
            <div class="color-success fonts-20 text-center">
                <i class="el-icon-success"></i>
            </div>
            <div class="fonts-12 margin-t-15 text-center">已成功提交企业版试用申请</div>
            <div class="fonts-12 margin-t-10 text-center">联系我们加急处理</div>
            <div class="text-center margin-t-10">
                <img src="../../../static/images/account/tencent-oa-qrcode.jpg" class="border-default radius-10" alt="wechat qrcode" style="width: 142px;"/>
            </div>
            <div slot="footer" class="text-center">
                <el-button type="primary" size="mini" round @click="contactUsDialog.qrcodeShow = false">确定</el-button>
            </div>
        </el-dialog>
        <alert-dialog ref="bindPhoneNode" message="当前账号未绑定手机号，请绑定后再继续操作" type="confirm" buttonName="去绑定" @confirmEvent="toBindPhoneEvent"></alert-dialog>
    </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import tools from "@/utils/tools";
import { commonApi, userApi, orgApi } from "@/utils/api";
import VipIcon from "@/components/icon/VipIcon";
import VipCheck from "@/components/icon/VipCheck";
import AlertDialog from '@/components/AlertDialog';
import UploadOss from '@/components/UploadOss';
export default {
    components: { VipIcon, VipCheck, AlertDialog, UploadOss },
    data() {
        return {
            tools, 
            moment,
            trialDialog: {
                show: false,
                loading: false,
            },
            activeType: 'vip', //char
            contactUsDialog: {
                qrcodeShow: false,
                show: false,
                loading: false,
                submitted: false,
                form: {
                    name: '',
                    email: '',
                    contact: '',
                    phone: '',
                    edition: '',
                    orgScale: '',
                    businessScene: '',
                    title: '',
                    course: '',
                }
            },
            purchaseOptions: [],
            selectOptions: {},
        }
    },
    computed: {
		...mapState({
			userInfo: state => state.user.userData,
		}),
	},
    created() {
        this.initPurchaseOptions();
        this.initApplyFormSelectOptions();
        let query = this.$route.query;
        if(this.userInfo.accountType === 'personal' && query.view === 'trial'){
            this.trialDialog.show = true;
        }
        if(query.view === 'char'){
            this.activeType = 'char';
            if(query.child){
                this.$nextTick(()=>{
                    this.showCharDialog(query.child === 'true' ? 'c10' : query.child);
                });
            }
        }
    },
    methods: {
        payProBtnEvent(){
            if (this.userInfo.accountType === 'personal'){
                this.trialDialog.show = true;
            }else if (['pro-trial','pro','pro-limited'].indexOf(this.userInfo.accountType)>-1){
                this.$store.dispatch('pay/setPayDialogAction',{show:true});
            }
        },
        // 申请试用
        applyBtnEvent(item) {
            if (item.type === 'Pro') {
                this.trialDialog.show = true;
            }else if (item.type === 'ENTERPRISE') {
                this.contactUsDialog.form.edition = 'TRIAL';
                this.applyEnterpriseEvent();
            }else if (item.type === 'ULTIMATE') {
                this.contactUsDialog.form.edition = 'ULTIMATE';
                this.applyEnterpriseEvent();
            }
        },
        // 审核中
        auditingBtnEvent(item) {
            if (item.type === 'ENTERPRISE') {
                this.contactUsDialog.qrcodeShow = true;
            }
        },
        // 立即购买
        purchaseBtnEvent(item) {
            if (item.type === 'Pro') {
                this.$store.dispatch('pay/setPayProDialogAction',{show: true});
            }else if (item.type === 'ENTERPRISE') {
                if(this.userInfo.phone){
                    this.$store.dispatch('pay/setPayErpDialogAction',{show: true});
                }else {
                    this.$refs.bindPhoneNode.setShow(true);
                }
            }
        },
        // 立即续费
        renewBtnEvent(item) {
            if (item.type === 'Pro') {
                this.$store.dispatch('pay/setPayProDialogAction',{show: true});
            }else if (item.type === 'ENTERPRISE') {
                 this.$store.dispatch('pay/setPayErpDialogAction',{show: true});
            }
        },
        cancelTrialBtnEvent(){
            this.trialDialog.show = false;
            this.$router.replace('/account/vip');
        },
        submitTrialBtnEvent(){
            let url = userApi.getProTrial;
            this.$ajax.post(url,{}).then(res=>{
                if(res.status === 200){
                    this.$message.success("申请成功");
                    this.trialDialog.show = false;
                    this.$store.dispatch("user/queryUserInfoAction");
                    setTimeout(()=>{
                        location.reload(true);
                    },1500);
                }
            })
        },
        showCharDialog(type){
            this.$store.dispatch('pay/setCharDialog',{show:true, type});
        },
        handlerContactUsDialogClose(){
            this.contactUsDialog = this.$options.data().contactUsDialog;
        },
        applyEnterpriseEvent(){
            if(this.userInfo.phone){
                this.contactUsDialog.show = true;
                this.initApplyFormSelectOptions();
            }else {
                this.$refs.bindPhoneNode.setShow(true);
            }
        },
        toBindPhoneEvent(){
            this.$router.push('/account/index');
        },
        submitContactsForm(){
            
            if(!this.contactUsDialog.form.contact){
                this.$message.error('请填写联系人');
                return;
            }
            if(!this.contactUsDialog.form.phone){
                this.$message.error('请填写联系手机');
                return;
            }else{
                if(!tools.checkPhone(this.contactUsDialog.form.phone)){
                    this.$message.error('请填正确的手机号');
                    return;
                }
            }
            if(!this.contactUsDialog.form.email){
                this.$message.error('请填写邮箱');
                return;
            }else{
                if(!tools.checkEmail(this.contactUsDialog.form.email)){
                    this.$message.error('请填写正确的邮箱');
                    return;
                }
            }
            if(!this.contactUsDialog.form.name){
                this.$message.error('请填写机构名称');
                return;
            }
            
            if(!this.contactUsDialog.form.orgScale){
                this.$message.error('请选择成员人数');
                return;
            }
            if(!this.contactUsDialog.form.businessScene){
                this.$message.error('请填写使用LanguageX的主要场景');
                return;
            }
            let tableFiles = [];
            if(this.contactUsDialog.form.edition === 'ULTIMATE') {
                tableFiles = this.$refs.uploadTableNode.getOssFiles();
                if(!this.contactUsDialog.form.title){
                    this.$message.error('请填写职务');
                    return;
                }
                if(!this.contactUsDialog.form.course){
                    this.$message.error('请填写所授课程');
                    return;
                }
                if(tableFiles.length === 0){
                    this.$message.error('请上传申请表附件');
                    return;
                }
            }
            
            let postData= {
                name: this.contactUsDialog.form.name,
                email: this.contactUsDialog.form.email,
                contact: this.contactUsDialog.form.contact,
                phone: this.contactUsDialog.form.phone,
                edition: this.contactUsDialog.form.edition,
                orgScale: this.contactUsDialog.form.orgScale,
                businessScene: this.contactUsDialog.form.businessScene,
            }
            if(this.contactUsDialog.form.edition === 'ULTIMATE') {
                postData = {
                    ...this.contactUsDialog.form,
                };
                postData.objectName = tableFiles[0].objectName;
            }
            this.contactUsDialog.loading = true;
            this.$ajax.post(orgApi.submitApplyTrial, postData).then(res => {
                if(res.status === 200){
                    console.log(this.contactUsDialog);
                    this.contactUsDialog.submitted = true;
                    this.initPurchaseOptions();
                }
            }).finally(() => {
                this.contactUsDialog.loading = false;
            })
            
        },
        connectUsEvent(){
            location.href = 'mailto:languagex@besteasy.com';
        },
        initPurchaseOptions () {
            this.$ajax.get(commonApi.queryPurchaseOptions).then(res => {
                if(res.status === 200){
                    this.purchaseOptions = res.data;
                }
            });
        },
        initApplyFormSelectOptions () {
            this.$ajax.get(commonApi.queryApplyFormSelectOptions).then(res => {
                if(res.status === 200){
                    this.selectOptions = res.data;
                }
            });
        }
    },
    mounted() {
        
    }
}
</script>
<style lang="scss" scoped>
.account-vip-page{
    .basic-card {
        box-shadow: initial;
    }
    .type-list{
        display: flex;
        background-color: #E5E5E5;
        box-sizing: border-box;
        // border: 1px solid rgba(0, 0, 0, 0.2);
        // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
        border-radius: 50px;
        .item{
            width: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            height: 36px;
            color: #212121;
            font-size: 15px;
            cursor: pointer;
            &.active{
                background-color: #212121;
                color: #ffffff;
            }
        }
    }
    .vip-type-list{
        padding: 0 20px;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill,minmax(240px,1fr));
        .item{
            padding: 30px 20px;
            // background-color: #fcfdce;
            min-height: 395px;
            .vip-icon{
                width: 40px;
                height: 40px;
                border-radius: 50px;
                font-size: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                &.free{
                    background-color: #F2F2F2;
                }
                &.pro{
                    background-color: #FFEBC4;
                }
                &.enterprise{
                    background-color: #DFD7F8;
                }
                 &.ultimate{
                    background-color: #C3D6FD;
                }
            }
            &.bg-free {
                background-color: #F8F8F8;
            }
            &.bg-pro {
                background-color: #FFF5E1;
            }
            &.bg-enterprise {
                background-color: #EFEBFB;
            }
            &.bg-ultimate {
                background-color: #E4E9FF;
            }
        }
    }
    .bg-current-version{
        position: absolute;
        left: 0;
        top: 0;
        background-image: url(../../../static/images/account/current-version.png);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        width: 66px;
        height: 66px;
    }
    .bg-left{
        background-image: url(../../../static/images/account/vip-bg-left.png);
        background-position: bottom;
        background-repeat: no-repeat;
    }
    .bg-center{
        background-image: url(../../../static/images/account/vip-bg-center.png);
        background-position: bottom;
        background-repeat: no-repeat;
    }
    .bg-right{
        background-image: url(../../../static/images/account/vip-bg-right.png);
        background-position: bottom;
        background-repeat: no-repeat;
    }
    .chars-pakage-list{
        padding: 0 20px;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill,minmax(240px,1fr));
        .item{
            display: flex;
            position: relative;
            align-items: center;
            flex-direction: column;
            background-color: #F8F8F8;
            min-height: 238px;
            .char-wapper {
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .char-icon{
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 23px;
                border-radius: 50px;
                background-color: #CCF7DE;
                color: #00D959;
            }
            .tag {
                border-radius: 0 10px 0 10px;
                background-color: #F56C6C;
                width: 48px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                font-size: 12px;
                color: #FFFFFF;
                position: absolute;
                top: -1px;
                right: -1px;
                &.erp {
                    background-color: #AA84DA;
                }
            }
            &.bg-yellow{
                background-color: #FFF5E1;
            }
            &.bg-purple{
                background-color: #EFEBFB;
            }
        }
    }
}
</style>
