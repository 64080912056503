<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="10" cy="10" r="10" fill="#D5F5EA" />
        <path
            d="M5.8335 10.4167L8.88905 13.3333L15.0002 7.5"
            stroke="#1D2130"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
<script>
export default {
    name: "VipPro",
    props: {
        size: {
            type: Number,
            default: 20,
        },
    },
};
</script>
